import React from "react";
import { graphql, StaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

import { Container, Grid } from "@material-ui/core";

import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

import Left from "../../../images/svg/left.svg";
import Right from "../../../images/svg/right.svg";

class EEslider extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      infinite: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 9000,
      speed: 300,
      slidesToShow: 1,
      slide: "div",
      slidesToScroll: 1,
    };

    return (
      <div className="White">
        <Slider
          ref={EEsliders => (this.slider = EEsliders)}
          {...settings}
          className="row-slider py-24"
        >
          {this.props.EEsliders.map(EEslide => {
            const SlideBg = [`${EEslide.fade_img.publicURL}`];

            return (
              <div key={EEslide.id} className="row-slider-wrap flex">
                <Container>
                  <Grid container item spacing={5} className="flex flex-row">
                    <Grid
                      item
                      md={6}
                      className="row-slider-text flex justify-center flex-col"
                    >
                      <div className="md:w-4/5 mx-auto">
                        <h3>{EEslide.fade_subtitle}</h3>
                        <ReactMarkdown
                          children={EEslide.fade_description}
                          className="md:w-4/5"
                        />
                        <div className="slider-buttons">
                          <button className="button" onClick={this.previous}>
                            <img src={Left} alt="View previous slide" />
                          </button>
                          <button className="button" onClick={this.next}>
                            <img src={Right} alt="View previous slide" />
                          </button>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={6} className="row-slider-image">
                      <img src={SlideBg} title="background image" />
                    </Grid>
                  </Grid>
                </Container>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query EEslider {
        strapiEnergyEfficiency {
          ee_slider {
            id
            fade_subtitle
            fade_description
            fade_img {
              publicURL
            }
          }
        }
      }
    `}
    render={data => (
      <EEslider EEsliders={data.strapiEnergyEfficiency.ee_slider} />
    )}
  />
);
